
import filter from 'lodash/filter';
import get from 'lodash/get';
import find from 'lodash/find';
import { trim } from 'lodash';

import individualTest from '../constants/json/checkboxes/individualTestPackages.json';
import MaterialTypes from '../constants/json/radiobuttons/materialTypes.json';
import {
  toggleAddMaterailModal,
  displaySubmitButtonTrf,
  displayClearButtonTrf,
  updateMaterialField,
  clearFormValueTRF,
  InitialProductTypeStore,
  currentProductTypeStore,
  onChangeProductType,
  onChangeScreeningInkVarient,
  getProductIdEdit,
  initialCertificationArry,
  recycledContentValue,
  onChangeRetest
} from '../actions/trfActions.js';
import { createServiceAction } from '../util/actionHelper.js';
import {
  brandInfoValidations,
  brandInfoValidationsForEdit,
  testingInfoValidations,
  labLocationValidations,
  clearTestingInfo,
  testingInfoValidationsForEdit,
  labLocationValidationsForEdit
} from '../validations/trfValidations/trfValidations.js';

const defaultState = {
  isAddMaterialModalOpen: false,
  updateMaterialField: [],
  initialCertificationArry: [],
  errorClassName: [true, true, true, true],
  sampleType: '',
  testingType: 'PR',
  toysPackage: [],
  isLoading: false,
  preLoadComponents: false,
  updateEnable: false,
  listLicenseeRemainingPages: {},
  countPerPage: {},
  totalCount: {},
  currentPage: {},
  nextPage: 1,
  InitialProductTypeStore: 1,
  currentProductTypeStore: 1,
  getProductIdEdit: 1,
  onChangeProductType: [],
  onChangeScreeningInkVarient: 0,
  recycledContentValue: 'No',
  isRetestEnable:'No'
};

let brandArray = {
  brandId: false,
  productId: false,
  isLicensee: false,
  isMaterial: '',
  isError: true,
  isRetest: false,
  originalTRF:'',
  materialType: [],
  testPackagesValidationArry:[]

};
let testInfoArray = {
  isMaterial: '',
  season: false,
  variants: false,
  testingType: 'PR',
  isMaterialTypeVariant: true,
  isMaterialType: false,
  materialNames: [],
  isVariant: true,
  materialType: '',
  pColor: '',
  sampleDescription: '',
  repellent: false,
  foodOrMouth: false,
  isTestingType: false,
  testPackages: [],
  testPackage: '',
  isTestPackageVariant: true,
  //subRecycledContent:'',
  //recycledContent:'',
  isError: true,
  subVariants: 0,
  sampleType: '',
  ageGroup: '',
  sampleColor: ''
};
let labSelectionArray = {
  islabSelect: false,
  locationPayload: '',
  labLocation: false,
  isServiceRequest: false,
  isError: true
};
let reviewSelectionArray = {
  isError: true
};

let pcxData = {
  typeOfMaterial: '',
  isCompleted: false
};

let activeForm = 'noForm';
let brandInfoModify = false;
let testingInfoModify = false;
let labLocationModify = false;

let currentProductId = 1;
let previewProductId = 1;
let editProductId = 1;

function validate(formDetails,isRetestEnable) {
  if (formDetails.meta.form === 'brandInfo' && formDetails.payload.brandInfoStatus === 'Edit' && activeForm !== 'noForm' && !brandInfoModify) {
    activeForm = formDetails.payload.activeForm;
    brandInfoModify = true;
    brandArray = brandInfoValidationsForEdit(formDetails.meta.field, formDetails.payload, 'Edit');
    if (
      brandArray.brandId &&
      brandArray.productId &&
      brandArray.isMaterial !== '' &&
      brandArray.isMaterialNumber !== ''
    ) {
      brandArray.isError = false;
    } else {
      brandArray.isError = true;
    }
    if (brandArray.isRetest === "Yes" && !brandArray.originalTRF) {
      brandArray.isError = true;
    }   
  }
  //The validation is for Edit the form & after change the form value it will read the testingInfoStatus as Create
  if (formDetails.meta.form === 'testingInfo' && formDetails.payload.testingInfoStatus === 'Edit'
    && activeForm !== formDetails.payload.activeForm && !testingInfoModify) {
    activeForm = formDetails.payload.activeForm;
    testingInfoModify = true;
    testInfoArray = testingInfoValidationsForEdit(formDetails.meta.field, formDetails.payload, editProductId);
    //To return the error true/false for Materials & Test packages section
    materialAndtestPackagesValidation(testInfoArray,isRetestEnable)
  }
  if (formDetails.meta.form === 'labLocation' && formDetails.payload.labInfoStatus === 'Edit' && activeForm !== formDetails.payload.activeForm && !labLocationModify) {

    labSelectionArray = labLocationValidationsForEdit(formDetails.meta.field, formDetails.payload, 'Edit');

    if (labSelectionArray[`labLocation${labSelectionArray.locationPayload}`]) {
      labSelectionArray.isError = false;
    } else {
      labSelectionArray.isError = true;
    }
    activeForm = formDetails.payload.activeForm;
    labLocationModify = true;
  }
  if (!brandArray.isError && !testInfoArray.isError && !labSelectionArray.isError) {
    reviewSelectionArray.isError = false;
  } else {
    reviewSelectionArray.isError = true;
  }
  return [brandArray.isError, testInfoArray.isError, labSelectionArray.isError, reviewSelectionArray.isError];
}

function materialAndtestPackagesValidation(testInfoArray,isRetestEnable) {
  //let recycleValidation = (testInfoArray.recycle === true && (testInfoArray.listRecycle !== "" && testInfoArray.describeRecycle !== ""))
  let recycleValidationTrue = (testInfoArray.recycle === true && (testInfoArray.listRecycle !== "" && testInfoArray.describeRecycle !== ""))
  let recycleValidationFalse = (testInfoArray.recycle === false)


  let returnFieldsStatus = testInfoArray.season && trim(testInfoArray.sampleDescription) !== ''
    && testInfoArray.sampleDescription !== 'N/A' && testInfoArray.sampleType !== '' && testInfoArray.ageGroup !== ''
    && testInfoArray.sampleColor !== '' && (recycleValidationTrue || recycleValidationFalse)
  /* && ((testInfoArray.subRecycledContent === 'Yes'  && testInfoArray.recycledContent === 'Yes' ) 
  || testInfoArray.recycledContent === 'No' ) */
  //testInfoArray.testingType = testInfoArray.testingType;

  //Edit TRF Form to Validate the Material & Individual Tests for Electric Product Type
  if ((editProductId === 204 || testInfoArray.productIdEdit === 204)) {
    toValidateElectronicProduct(testInfoArray, returnFieldsStatus,isRetestEnable)
  }
  else if ((editProductId === 205 || testInfoArray.productIdEdit === 205)) {
    toValidateJewelryProduct(testInfoArray, returnFieldsStatus,isRetestEnable)
  }
  else if ((editProductId === 206 || testInfoArray.productIdEdit === 206)) {
    toValidateAccessoriesProduct(testInfoArray, returnFieldsStatus)
  }
  else if (editProductId === 200 || editProductId === 201 || editProductId === 202 || editProductId === 203 || testInfoArray.productIdEdit === 200
    || testInfoArray.productIdEdit === 201 || testInfoArray.productIdEdit === 202 || testInfoArray.productIdEdit === 203) {
    toValidatePrRd(testInfoArray, returnFieldsStatus,isRetestEnable)
  }
  if (editProductId !== 204 || testInfoArray.productIdEdit !== 204) {
    toValidateMaterialSubOptions(testInfoArray, returnFieldsStatus)
  }
  if (testInfoArray.isMaterial === '') {
    testInfoArray.isError = true;
  }
  if (testInfoArray.multiVariantMaterial && testInfoArray.multiVariants.filter(element => element != null).length === 0) {
    testInfoArray.isError = true;
  }
}
function materialChangeTestingInfo(testInfoArray, brandArray) {
  if (
    testInfoArray.season &&
    brandArray.isMaterial !== '' &&
    !(testInfoArray.materialNames.filter(element => element != null).length === 0) &&
    !testInfoArray.isMaterialVarient &&
    testInfoArray.testingType === 'PR'
  ) {
    return false;
  } else if (
    testInfoArray.season &&
    brandArray.isMaterial !== '' &&
    !(testInfoArray.materialNames.filter(element => element != null).length === 0) &&
    testInfoArray.allVarientPresent &&
    testInfoArray.testingType === 'PR'
  ) {
    return false;
  } else if (testInfoArray.season && testInfoArray.testingType === 'RD' && testInfoArray.repellent && brandArray.isMaterial !== '') {
    return false;
  } else if (
    testInfoArray.season &&
    brandArray.isMaterial !== '' &&
    testInfoArray[`tp-variants${testInfoArray.testPackage}`] &&
    testInfoArray.testingType === 'RD' &&
    !testInfoArray.repellent
  ) {
    return false;
  } else if (
    testInfoArray.season &&
    brandArray.isMaterial !== '' &&
    !(testInfoArray.testPackagesValidationArry.filter(element => element != null).length === 0) &&
    !testInfoArray.isTestPackageVariant &&
    testInfoArray.testingType === 'RD' &&
    !testInfoArray.repellent
  ) {
    return false;
  }
  if (testInfoArray.multiVariantMaterial && testInfoArray.multiVariants.filter(element => element != null).length === 0) {
    return true;
  }
  return true
}

function brandInfoValidationsArry(formDetails, testInfoArray) {
  if (formDetails.meta.form === 'brandInfo') {
    brandArray = brandInfoValidations(formDetails.meta.field, formDetails.payload, 'Create');
    let testinInfoCheckForMaterialChange = materialChangeTestingInfo(testInfoArray, brandArray)
    if (
      brandArray.brandId &&
      brandArray.productId &&
      brandArray.isLicensee &&
      brandArray.isMaterialNumber !== ''
    ) {
      brandArray.isError = false;
    } else {
      brandArray.isError = true;
    }
    if (brandArray.isLicensee === "Yes" && !brandArray.licensee) {
      brandArray.isError = true;
    }
    if (brandArray.isRetest === "Yes" && !brandArray.originalTRF ) {
      brandArray.isError = true;
    }    
    if (brandArray.isMaterial === '') {
      brandArray.isError = true;
      testInfoArray.isError = true;
    }
    if(brandArray.materialType && brandArray.materialType[0] === null){
      testInfoArray.isError = true;
    }
    if(brandArray.testPackagesValidationArry ===  null){
      testInfoArray.testPackagesValidationArry = [null]
      testInfoArray.testPackagesForEdit=[null]
      delete testInfoArray[`tp-variants${testInfoArray.testPackage}`]
      testInfoArray.testPackages = []
      testInfoArray.testPackageVariants = []
      testInfoArray.variants = false
      testInfoArray.selectedPackages = true
      testInfoArray.testPackage = ''
      testInfoArray.isTestPackageVariant = false;
      testInfoArray.testPackagesElectronicValidationArry = []
      testInfoArray.testPackagesJewelryValidationArry = []
      testInfoArray.testPackagesPackagingValidationArry = []
      testInfoArray.isError = true;
      testInfoArray.materialNames = [null]
    }
    if (!testinInfoCheckForMaterialChange && brandArray.testPackagesValidationArry !==  null) {
      testInfoArray.isError = false;
    } else {
      testInfoArray.isError = true;
    }
  }
}
function toValidateMetalItems(testInfoArray, returnFieldsStatus){
  if (testInfoArray.materialNames.filter(element => element !== null).includes(409) 
  && !testInfoArray.materialNames.filter(element => element !== null).includes(405)) {
    if (
      (testInfoArray[`variants409`] === 4091 ||
        testInfoArray[`variants409`] === 4093) &&
      (testInfoArray.testPackagesValidationArry.filter(element => element !== null).includes(521)) && returnFieldsStatus
    ) {
      testInfoArray.isError = false;
    }
    else if (
      (testInfoArray[`variants409`] === 4090 ||
        testInfoArray[`variants409`] === 4092) &&
       returnFieldsStatus
    ) {
      testInfoArray.isError = false;
    }else{
      testInfoArray.isError = true;
    }
  }
}
//To validate the Material Variants validation
function toValidateMaterialSubOptions(testInfoArray, returnFieldsStatus) {
  if (testInfoArray.materialNames.filter(element => element != null).length > 0) {
    if (testInfoArray.materialNames.filter(element => element != null).includes(405)) {
      if ((testInfoArray[`variants405`] === 4040) && testInfoArray.pColor === '' && !returnFieldsStatus) {
        testInfoArray.isError = true;
      }
      if (testInfoArray[`variants405`] === 4052) {
        testInfoArray.isError = true;
        if (testInfoArray[`variants405`] === 4052 && (testInfoArray.subVariants === 4050 || testInfoArray.subVariants === 4051)
          && testInfoArray.pColor !== '' && returnFieldsStatus) {
          testInfoArray.isError = false;
        } else {
          testInfoArray.isError = true;
        }
      }
    }
    if (testInfoArray.materialNames.filter(element => element !== null).includes(408)) {
      if((testInfoArray[`variants408`] === 4080 ||
      testInfoArray[`variants408`] === 4081) && testInfoArray.pColor !== '' && returnFieldsStatus){
        testInfoArray.isError = false;
      }else{
        testInfoArray.isError = true;
      }
    }
    if (testInfoArray.materialNames.filter(element => element !== null).includes(409)) {
      toValidateMetalItems(testInfoArray, returnFieldsStatus)
    }
    if (!(testInfoArray.materialNames.filter(element => element != null).length === 0)) {
      testInfoArray.materialNames.filter(element => element != null).map(materialId => {
        if (!(Object.prototype.hasOwnProperty.call(testInfoArray, `variants${materialId}`))
          && find(MaterialTypes, ['id', materialId]).variant ) {
          testInfoArray.isError = true
          if (find(MaterialTypes, ['id', materialId]).variant && !returnFieldsStatus && materialId !== 405) {
            testInfoArray.isError = true
          } 
        }
        return null
      })
    }
  }
}
//This Validation is only for Product Type 204 (Electronics)
function tovalidateInksAndPaintsForElectronic(testInfoArray, returnFieldsStatus) {
  if (testInfoArray.materialNames.filter(element => element != null).length > 0) {
    if (testInfoArray.materialNames.filter(element => element != null).includes(405)) {
      if ((testInfoArray[`variants405`] === 4040) && testInfoArray.pColor === '' && testInfoArray.electCategory === "") {
        testInfoArray.isError = true;
      }
      if (testInfoArray[`variants405`] === 4052) {
        testInfoArray.isError = true;
        if (testInfoArray[`variants405`] === 4052 && (testInfoArray.subVariants === 4050 || testInfoArray.subVariants === 4051)
          && testInfoArray.electCategory !== "" && testInfoArray.pColor !== '') {
          testInfoArray.isError = false;
        } else {
          testInfoArray.isError = true;
        }
      }
    }
    if (testInfoArray.materialNames.filter(element => element !== null).includes(408)) {
      if((testInfoArray[`variants408`] === 4080 ||
      testInfoArray[`variants408`] === 4081) && testInfoArray.pColor !== '' && returnFieldsStatus){
        testInfoArray.isError = false;
      }else{
        testInfoArray.isError = true;
      }
    }
    if (testInfoArray.materialNames.filter(element => element !== null).includes(409)) {
      toValidateMetalItems(testInfoArray, returnFieldsStatus)
    }
    if (!(testInfoArray.materialNames.filter(element => element != null).length === 0)) {
      testInfoArray.materialNames.filter(element => element != null).map(materialId => {
        if (!(Object.prototype.hasOwnProperty.call(testInfoArray, `variants${materialId}`))
          && find(MaterialTypes, ['id', materialId]).variant ) {
          testInfoArray.isError = true
          if (find(MaterialTypes, ['id', materialId]).variant && !returnFieldsStatus && materialId !== 405) {
            testInfoArray.isError = true
          }
        }
        return null
      })
    }
 
  }
}


function toValidateJewelryProduct(testInfoArray, returnFieldsStatus,isRetestEnable) {
  if (testInfoArray.testingType === 'RD'|| (testInfoArray.testingType === 'PR' && isRetestEnable === 'Yes')) {
    if ((testInfoArray.jewelryMaterialTypeValidation === " " || testInfoArray.jewelryMaterialTypeValidation === "")) {
      if (
        returnFieldsStatus
        && testInfoArray.testPackagesJewelryValidationArry.filter(element => element !== null).length > 0
        && testInfoArray.testingType === 'RD') {
        testInfoArray.isError = false;
      } else {
        testInfoArray.isError = true;
      }
    } else if (testInfoArray.jewelryMaterialTypeValidation !== "" || testInfoArray.jewelryMaterialTypeValidation === " ") {
      tovalidateJewelryMaterialValidation(testInfoArray, returnFieldsStatus)
    }

  }
  else if (testInfoArray.testingType === 'PR') {
    if (testInfoArray.jewelryMaterialTypeValidation !== '' && testInfoArray.testingType === 'PR') {
      if (testInfoArray.jewelryMaterialTypeValidation === 4148) {
        if (testInfoArray[`variants4148`] && (testInfoArray[`variants4148`] === 4146 || testInfoArray[`variants4148`] === 4142) && returnFieldsStatus) {
          testInfoArray.isError = false;
        } else {
          testInfoArray.isError = true;
        }
      }
      else if (testInfoArray.jewelryMaterialTypeValidation === 4147) {
        if (testInfoArray[`variants4147`] && (testInfoArray[`variants4147`] === 4141 || testInfoArray[`variants4147`] === 4145) && returnFieldsStatus) {
          testInfoArray.isError = false;
        } else {
          testInfoArray.isError = true;
        }
      }
      else if (testInfoArray.jewelryMaterialTypeValidation === 4148 && testInfoArray.testingInfoStatus === "Edit" && returnFieldsStatus) {
        testInfoArray.isError = false;
      }
      else if ((testInfoArray.jewelryMaterialTypeValidation === 4144 || testInfoArray.jewelryMaterialTypeValidation === 4143)
        && returnFieldsStatus) {
        testInfoArray.isError = false;
      }
      else if ((testInfoArray.jewelryMaterialTypeValidation === 4146 || testInfoArray.jewelryMaterialTypeValidation === 4142
        || testInfoArray.jewelryMaterialTypeValidation === 4141 || testInfoArray.jewelryMaterialTypeValidation === 4145) && returnFieldsStatus) {
        testInfoArray.isError = false;
      }
      else {
        testInfoArray.isError = true;
      }
    }
  }
  else if ((testInfoArray.jewelryMaterialTypeValidation === '' || testInfoArray.jewelryMaterialTypeValidation === ' ')
    && testInfoArray.testingType === 'PR' && !returnFieldsStatus) {
    testInfoArray.isError = true;
  }
}


function toValidateElectronicProduct(testInfoArray, returnFieldsStatus, isRetestEnable) {
  if (testInfoArray.testingType === 'RD' || (testInfoArray.testingType === 'PR' && defaultState.isRetestEnable === 'Yes')) {
    const materialNumbersArry = testInfoArray.materialNames.filter(element => (element !== null && element !== 4150 && element !== 4152)).length
    const testPakageElectronics = testInfoArray.testPackagesElectronicValidationArry.filter(element => (element !== null)).length
    if (testInfoArray.skinContact === true) {
      let electCategoryStatus =testInfoArray.electCategory !== "" ? true : false
      returnFieldsStatus = returnFieldsStatus && electCategoryStatus
      if (returnFieldsStatus 
        && materialNumbersArry > 0) {
        testInfoArray.isError = false;
        tovalidateInksAndPaintsForElectronic(testInfoArray, returnFieldsStatus)
      } else {
        testInfoArray.isError = true;
      }
    }
    else if (testInfoArray.skinContact === false) {
      if (returnFieldsStatus &&
        (testInfoArray.electCategory !== "" ||
          (testPakageElectronics > 0))) {
        testInfoArray.isError = false;
        } else {
        testInfoArray.isError = true;
      }
      tovalidateInksAndPaintsForElectronic(testInfoArray, returnFieldsStatus)
    }
  } else if (testInfoArray.testingType === 'PR' && isRetestEnable !== 'Yes') {
    //brandArray.isMaterial is comming from Create Validation
    //testInfoArray.isMaterial is comming from Edit Validation (testingInfoValidationsForEdit function)
    let electCategoryStatus =testInfoArray.electCategory !== "" ? true : false
    returnFieldsStatus = returnFieldsStatus && electCategoryStatus
    if (testInfoArray.skinContact === true) {
      if (
        returnFieldsStatus &&
        (brandArray.isMaterial !== '' || testInfoArray.isMaterial !== '') &&
        !(testInfoArray.materialNames.filter(element => element != null && element !== 4150 && element !== 4152).length === 0) //4150 & 4152 already added in materialNames array
      ) {
        testInfoArray.isError = false
        tovalidateInksAndPaintsForElectronic(testInfoArray, returnFieldsStatus)
      } else {
        testInfoArray.isError = true
      }
    } else if (testInfoArray.skinContact === false) {
      if(returnFieldsStatus){
      testInfoArray.isError = false;
      if (testInfoArray.materialNames.filter(element => element != null).length > 0) {
        if (testInfoArray.materialNames.filter(element => element != null).includes(405)) {
          if ((testInfoArray[`variants405`] === 4040) && testInfoArray.pColor === '') {
            testInfoArray.isError = true;
          }
          if (testInfoArray[`variants405`] === 4052) {
            testInfoArray.isError = true;
            if (testInfoArray[`variants405`] === 4052 && (testInfoArray.subVariants === 4050 || testInfoArray.subVariants === 4051)
              && testInfoArray.pColor !== '') {
              testInfoArray.isError = false;
            } else {
              testInfoArray.isError = true;
            }
          }
        }
        else if (testInfoArray.materialNames.filter(element => element !== null).includes(408)) {
          if((testInfoArray[`variants408`] === 4080 ||
          testInfoArray[`variants408`] === 4081) && testInfoArray.pColor !== '' && returnFieldsStatus){
            testInfoArray.isError = false;
          }else{
            testInfoArray.isError = true;
          }
        }
        else if(testInfoArray.materialNames.filter(element => element !== null).includes(409)){
          toValidateMetalItems(testInfoArray, returnFieldsStatus)
        }
        if (!(testInfoArray.materialNames.filter(element => element != null).length === 0)) {
          testInfoArray.materialNames.filter(element => element != null).map(materialId => {
            if (!(Object.prototype.hasOwnProperty.call(testInfoArray, `variants${materialId}`))
              && find(MaterialTypes, ['id', materialId]).variant) {
              if (find(MaterialTypes, ['id', materialId]).variant) {
                testInfoArray.isError = true
              }
            }
            return null
          })
        }
      }
    }else{
      testInfoArray.isError = true;
      }
    }
  }
  
}
function tovalidateJewelryMaterialValidation(testInfoArray, returnFieldsStatus) {
  if (testInfoArray.jewelryMaterialTypeValidation === 4148) {
    if ((testInfoArray[`variants4148`] === 4146 || testInfoArray[`variants4148`] === 4142) && returnFieldsStatus) {
      testInfoArray.isError = false;
    }
  }
  else if (testInfoArray.jewelryMaterialTypeValidation === 4147) {
    if (testInfoArray[`variants4147`] && (testInfoArray[`variants4147`] === 4141 || testInfoArray[`variants4147`] === 4145) && returnFieldsStatus) {
      testInfoArray.isError = false;
    }
  }
  else if ((testInfoArray.jewelryMaterialTypeValidation === 4144 || testInfoArray.jewelryMaterialTypeValidation === 4143) && returnFieldsStatus) {
    testInfoArray.isError = false;
  }
  else if ((testInfoArray.jewelryMaterialTypeValidation === 4146 || testInfoArray.jewelryMaterialTypeValidation === 4142
    || testInfoArray.jewelryMaterialTypeValidation === 4141 || testInfoArray.jewelryMaterialTypeValidation === 4145) && returnFieldsStatus) {
    testInfoArray.isError = false;
  } else {
    testInfoArray.isError = true;
  }
}

function accessoriesProductTypePr(testInfoArray, returnFieldsStatus) {
  if (
    returnFieldsStatus &&
    (brandArray.isMaterial !== '' || testInfoArray.isMaterial !== '') &&
    !(testInfoArray.materialNames.filter(element => element != null && element !== 4150 && element !== 4152
      && element !== 4143 && element !== 4144 && element !== 4147 && element !== 4148).length === 0) &&
    !testInfoArray.isMaterialVarient &&
    testInfoArray.testingType === 'PR'
  ) {
    toValidateMaterialSubOptions(testInfoArray, returnFieldsStatus)
    testInfoArray.isError = false;
  }
  else if (
    returnFieldsStatus &&
    (brandArray.isMaterial !== '' || testInfoArray.isMaterial !== '') &&
    !(testInfoArray.materialNames.filter(element => element != null && element !== 4150 && element !== 4152
      && element !== 4143 && element !== 4144 && element !== 4147 && element !== 4148).length === 0) &&
    testInfoArray.allVarientPresent &&
    testInfoArray.testingType === 'PR'
  ) {
    toValidateMaterialSubOptions(testInfoArray, returnFieldsStatus)
    testInfoArray.isError = false;
  }
  else if (testInfoArray.jewelryMaterialTypeValidation !== '' && testInfoArray.jewelryMaterialTypeValidation !== ' ' &&
    (brandArray.isMaterial !== '' || testInfoArray.isMaterial !== '')) {
    tovalidateJewelryMaterialValidation(testInfoArray, returnFieldsStatus)
  }
  else if (
    returnFieldsStatus &&
    (testInfoArray.electCategory !== "") &&
    (brandArray.isMaterial !== '' || testInfoArray.isMaterial !== '')
  ) {
    testInfoArray.isError = false
  }
  else {
    testInfoArray.isError = true;
  }
  
 
}
function accessoriesProductTypeRd(testInfoArray, returnFieldsStatus) {
  if (
    returnFieldsStatus &&
    (brandArray.isMaterial !== '' || testInfoArray.isMaterial !== '') &&
    !(testInfoArray.materialNames.filter(element => element != null && element !== 4150 && element !== 4152
      && element !== 4143 && element !== 4144 && element !== 4147 && element !== 4148).length === 0) &&
    !testInfoArray.isMaterialVarient &&
    testInfoArray.testingType === 'RD'
  ) {
    toValidateMaterialSubOptions(testInfoArray, returnFieldsStatus)
    testInfoArray.isError = false;
  }
  else if (
    returnFieldsStatus &&
    (brandArray.isMaterial !== '' || testInfoArray.isMaterial !== '') &&
    !(testInfoArray.materialNames.filter(element => element != null && element !== 4150 && element !== 4152
      && element !== 4143 && element !== 4144 && element !== 4147 && element !== 4148).length === 0) &&
    testInfoArray.allVarientPresent &&
    testInfoArray.testingType === 'RD'
  ) {
    toValidateMaterialSubOptions(testInfoArray, returnFieldsStatus)
    testInfoArray.isError = false;
  }
  else if (testInfoArray.jewelryMaterialTypeValidation !== '' && testInfoArray.jewelryMaterialTypeValidation !== ' ' &&
    (brandArray.isMaterial !== '' || testInfoArray.isMaterial !== '')) {
    tovalidateJewelryMaterialValidation(testInfoArray, returnFieldsStatus)
  }
  else if (
    returnFieldsStatus &&
    (brandArray.isMaterial !== '' || testInfoArray.isMaterial !== '') &&
    !(testInfoArray.testPackagesElectronicValidationArry.filter(element => element != null).length === 0) &&
    !testInfoArray.isTestPackageVariant &&
    testInfoArray.testingType === 'RD'
  ) {
    testInfoArray.isError = false;
  }
  else if (
    returnFieldsStatus &&
    (brandArray.isMaterial !== '' || testInfoArray.isMaterial !== '') &&
    !(testInfoArray.testPackagesPackagingValidationArry.filter(element => element != null).length === 0) &&
    !testInfoArray.isTestPackageVariant &&
    testInfoArray.testingType === 'RD'
  ) {
    testInfoArray.isError = false;
  }
  else if (
    returnFieldsStatus &&
    (brandArray.isMaterial !== '' || testInfoArray.isMaterial !== '') &&
    !(testInfoArray.testPackagesForEdit.filter(element => element != null).length === 0) &&
    !testInfoArray.isTestPackageVariant &&
    testInfoArray.testingType === 'RD'
  ) {
    testInfoArray.isError = false;
  }
  else if (
    returnFieldsStatus &&
    (testInfoArray.electCategory !== "") &&
    (brandArray.isMaterial !== '' || testInfoArray.isMaterial !== '')
  ) {
    testInfoArray.isError = false
  }
  else if (
    returnFieldsStatus && (brandArray.isMaterial !== '' || testInfoArray.isMaterial !== '') &&
    !(testInfoArray.testPackagesValidationArry.filter(element => element != null).length === 0) &&
    !testInfoArray.isTestPackageVariant &&
    testInfoArray.testingType === 'RD'
  ) {
    testInfoArray.isError = false;
  }
  else {
    testInfoArray.isError = true;
  }

}

function toValidateAccessoriesProduct(testInfoArray, returnFieldsStatus, editProductId) {
  if (testInfoArray.testingType === 'PR') {
    accessoriesProductTypePr(testInfoArray, returnFieldsStatus)
  } else if (testInfoArray.testingType === 'RD') {
    accessoriesProductTypeRd(testInfoArray, returnFieldsStatus)
  }
  }
function toValidatePrRd(testInfoArray, returnFieldsStatus,isRetestEnable) {
if (testInfoArray.testingType === 'PR' && isRetestEnable!== 'Yes') {
    if (
      returnFieldsStatus &&
      (brandArray.isMaterial !== '' || testInfoArray.isMaterial !== '') &&
      !(testInfoArray.materialNames.filter(element => element != null).length === 0) &&
      !testInfoArray.isMaterialVarient &&
      testInfoArray.testingType === 'PR'
    ) {
      testInfoArray.isError = false;
    }
    else if (
      returnFieldsStatus &&
      (brandArray.isMaterial !== '' || testInfoArray.isMaterial !== '') &&
      !(testInfoArray.materialNames.filter(element => element != null).length === 0) &&
      //testInfoArray.allVarientPresent &&
      testInfoArray.testingType === 'PR'
    ) {
      testInfoArray.isError = false;
    }
    else {
      testInfoArray.isError = true;
    }
  }
  else if (testInfoArray.testingType === 'RD' ||(isRetestEnable === 'Yes' && testInfoArray.testingType === 'PR' )) {
    if (
      returnFieldsStatus &&
      (brandArray.isMaterial !== '' || testInfoArray.isMaterial !== '') &&
      !(testInfoArray.materialNames.filter(element => element != null).length === 0) &&
      !testInfoArray.isMaterialVarient &&
      (testInfoArray.testingType === 'RD' ||(isRetestEnable === 'Yes' && testInfoArray.testingType === 'PR' ))
    ) {
      testInfoArray.isError = false;
    }
    else if (
      returnFieldsStatus &&
      (brandArray.isMaterial !== '' || testInfoArray.isMaterial !== '') &&
      !(testInfoArray.materialNames.filter(element => element != null).length === 0) &&
      testInfoArray.allVarientPresent &&
      (testInfoArray.testingType === 'RD' ||(isRetestEnable === 'Yes' && testInfoArray.testingType === 'PR' ))
    ) {
      testInfoArray.isError = false;
    } else if (
      returnFieldsStatus && (brandArray.isMaterial !== '' || testInfoArray.isMaterial !== '') &&
      !(testInfoArray.testPackagesValidationArry.filter(element => element != null).length === 0) &&
      !testInfoArray.isTestPackageVariant &&
      (testInfoArray.testingType === 'RD' ||(isRetestEnable === 'Yes' && testInfoArray.testingType === 'PR' ))
    ) {
      testInfoArray.isError = false;
    } else if (
      returnFieldsStatus &&
      (brandArray.isMaterial !== '' || testInfoArray.isMaterial !== '') &&
      !(testInfoArray.testPackagesForEdit.filter(element => element != null).length === 0) &&
      !testInfoArray.isTestPackageVariant &&
      (testInfoArray.testingType === 'RD' ||(isRetestEnable === 'Yes' && testInfoArray.testingType === 'PR' ))
    ) {
      testInfoArray.isError = false;
    }
    else if (
      returnFieldsStatus && (brandArray.isMaterial !== '' || testInfoArray.isMaterial !== '') &&
      testInfoArray[`tp-variants${testInfoArray.testPackagesValidationArry}`] && (testInfoArray.testingType === 'RD' ||(isRetestEnable === 'Yes' && testInfoArray.testingType === 'PR' )) &&
      !testInfoArray.repellent
    ) {
      testInfoArray.isError = false;
    }
    else {
      testInfoArray.isError = true;
    }
    if (editProductId === 203 || testInfoArray.productIdEdit === 203) {
      if (
        returnFieldsStatus && (brandArray.isMaterial !== '' || testInfoArray.isMaterial !== '') &&
        !(testInfoArray.testPackagesPackagingValidationArry.filter(element => element != null).length === 0) &&
        !testInfoArray.isTestPackageVariant &&
        (testInfoArray.testingType === 'RD' ||(isRetestEnable === 'Yes' && testInfoArray.testingType === 'PR' ))
      ) {
        testInfoArray.isError = false;
      }
    }
  }
}
function isValidated(formDetails,isRetestEnable) {
  brandInfoValidationsArry(formDetails, testInfoArray)
  if (formDetails.meta.form === 'testingInfo') {
    testInfoArray = testingInfoValidations(formDetails.meta.field, formDetails.payload, 'Create', currentProductId, previewProductId, editProductId);
    let recycleValidationTrue = (testInfoArray.recycle === true && (testInfoArray.listRecycle !== "" && testInfoArray.describeRecycle !== ""))
    let recycleValidationFalse = (testInfoArray.recycle === false)
    let returnFieldsStatus = testInfoArray.season && trim(testInfoArray.sampleDescription) !== ''
      && testInfoArray.sampleDescription !== 'N/A' && testInfoArray.sampleType !== '' && testInfoArray.ageGroup !== '' && testInfoArray.sampleColor !== ''
      && (brandArray.isMaterial !== '' || testInfoArray.isMaterial !== '')
      && (recycleValidationTrue || recycleValidationFalse)

    //testInfoArray.testingType = testInfoArray.testingType;
    //Create TRF Form to Validate the Material & Individual Tests for Electric Product Type
    if ((editProductId === 204 || testInfoArray.productIdEdit === 204)) {
      toValidateElectronicProduct(testInfoArray, returnFieldsStatus,isRetestEnable)
    }
    else if ((editProductId === 205 || testInfoArray.productIdEdit === 205)) {
      toValidateJewelryProduct(testInfoArray, returnFieldsStatus,isRetestEnable)
    }
    else if ((editProductId === 206 || testInfoArray.productIdEdit === 206)) {
      toValidateAccessoriesProduct(testInfoArray, returnFieldsStatus)
    }
    else if (editProductId === 200 || editProductId === 201 || editProductId === 202 || editProductId === 203
      || testInfoArray.productIdEdit === 200 || testInfoArray.productIdEdit === 201 || testInfoArray.productIdEdit === 202 || testInfoArray.productIdEdit === 203) {
      toValidatePrRd(testInfoArray, returnFieldsStatus,isRetestEnable)
    }
    if (editProductId !== 204 || testInfoArray.productIdEdit !== 204) {
      toValidateMaterialSubOptions(testInfoArray, returnFieldsStatus)
    }

    //To check the Test Packages Sub options
    const AcidicVarients = filter(individualTest[0].common, { id: 501 })[0]
      .variants.map(variant => variant.id)
      .some(selectedPackage =>
        testInfoArray.testPackageVariants.filter(element => element != null).includes(selectedPackage)
      );
    const metalsChromiumVarients = filter(individualTest[0].common, { id: 601 })[0]
      .variants.map(variant => variant.id)
      .some(selectedPackage =>
        testInfoArray.testPackageVariants.filter(element => element != null).includes(selectedPackage)
      );
    const metalsNickelVarients = filter(individualTest[0].common, { id: 521 })[0]
      .variants.map(variant => variant.id)
      .some(selectedPackage =>
        testInfoArray.testPackageVariants.filter(element => element != null).includes(selectedPackage)
      );
    const metalsExtractableVarients = filter(individualTest[0].common, { id: 520 })[0]
      .variants.map(variant => variant.id)
      .some(selectedPackage =>
        testInfoArray.testPackageVariants.filter(element => element != null).includes(selectedPackage)
      );
    const JewelleryVarients = filter(individualTest[0].jewelry, { id: 590 })[0]
      .variants.map(variant => variant.id)
      .some(selectedPackage =>
        testInfoArray.testPackageVariants.filter(element => element != null).includes(selectedPackage)
      );
    if (!AcidicVarients && testInfoArray.testPackagesValidationArry.length > 0 && testInfoArray.testPackagesValidationArry.filter(element => element != null).includes(501)) {
      testInfoArray.isError = true;
    }
    if (!metalsChromiumVarients && testInfoArray.testPackagesValidationArry.length > 0 && testInfoArray.testPackagesValidationArry.filter(element => element != null).includes(601)) {
      testInfoArray.isError = true;
    }
    if (!metalsNickelVarients && testInfoArray.testPackagesValidationArry.length > 0 && testInfoArray.testPackagesValidationArry.filter(element => element != null).includes(521)) {
      testInfoArray.isError = true;
    }
    if (!metalsExtractableVarients && testInfoArray.testPackagesValidationArry.length > 0 && testInfoArray.testPackagesValidationArry.filter(element => element != null).includes(520)) {
      testInfoArray.isError = true;
    }
    if (!JewelleryVarients && testInfoArray.testPackagesJewelryValidationArry.filter(element => element != null).includes(590)) {
      testInfoArray.isError = true;
    }
  }
  if (formDetails.meta.form === 'labLocation') {
    labSelectionArray = labLocationValidations(formDetails.meta.field, formDetails.payload, 'Create');
    if (labSelectionArray[`labLocation${labSelectionArray.locationPayload}`]) {
      labSelectionArray.isError = false;
    } else {
      labSelectionArray.isError = true;
    }
  }
  if (!brandArray.isError && !testInfoArray.isError && !labSelectionArray.isError) {
    reviewSelectionArray.isError = false;
    defaultState.updateEnable = true;
  } else {
    reviewSelectionArray.isError = true;
  }
  return [brandArray.isError, testInfoArray.isError, labSelectionArray.isError, reviewSelectionArray.isError];
}

function getInitialProductId(id) {
  currentProductId = id
}
function getCurrentProductId(id) {
  previewProductId = id
}
function editFormProductId(id) {
  editProductId = id
}

function clearValues(action) {
  if (get(action, 'meta.form') !== 'addMaterial') {
    brandArray = {
      brandId: false,
      productId: false,
      isMaterial: '',
      labName: '',
      isError: true,
      isRetest:false,
      originalTRF: null
    };
    testInfoArray = {
      season: false,
      variants: false,
      isMaterialTypeVariant: true,
      isMaterial: '',
      isMaterialType: false,
      materialType: '',
      pColor: '',
      sampleDescription: '',
      isTestingType: false,
      testPackages: [],
      repellent: false,
      //subRecycledContent:'',
      //recycledContent:'',
      isError: true,
      sampleType: '',
      ageGroup: '',
      sampleColor: ''
    };
    labSelectionArray = {
      islabSelect: false,
      locationPayload: '',
      labLocation: false,
      isServiceRequest: false,
      isError: true
    };
    reviewSelectionArray = {
      isError: true
    };
    brandInfoModify = false
    testingInfoModify = false;
    labLocationModify = false;
    clearTestingInfo();
    return defaultState.errorClassName;
  }
  return [brandArray.isError, testInfoArray.isError, labSelectionArray.isError, reviewSelectionArray.isError];
}

function isMaterialFilled(formDetails) {
  if (formDetails.meta.form === 'addMaterial') {
    if (formDetails.meta.field.indexOf('typeOfMaterial') !== -1) {
      pcxData.typeOfMaterial = formDetails.payload;
    }
    if (pcxData.typeOfMaterial === '') {
      pcxData.isCompleted = false;
    }
    else {
      pcxData.isCompleted = true;
    }
  }
  return pcxData.isCompleted;
}

function clearMaterialFilled(action) {
  if (get(action, 'meta.form') === 'addMaterial') {
    pcxData = {
      typeOfMaterial: '',
      isCompleted: false
    };

  }
  return false;
}


export default function statusButton(state = defaultState, action) {
  switch (action.type) {
    case toggleAddMaterailModal.type:
      return { ...state, isAddMaterialModalOpen: action.payload };
    case displaySubmitButtonTrf.type:
      return { ...state, shouldDisplaySubmit: action.payload };
    case displayClearButtonTrf.type:
      return { ...state, shouldDisplayClear: action.payload };
    case updateMaterialField.type:
      return { ...state, updateMaterialField: action.payload };
    case onChangeProductType.type:
      return { ...state, onChangeProductType: action.payload };
    case onChangeScreeningInkVarient.type:
      return { ...state, onChangeScreeningInkVarient: action.payload };
    case currentProductTypeStore.type:
      return { ...state, currentProductTypeStore: getCurrentProductId(action.payload) };
    case InitialProductTypeStore.type:
      return { ...state, InitialProductTypeStore: getInitialProductId(action.payload) };
    case getProductIdEdit.type:
      return { ...state, getProductIdEdit: editFormProductId(action.payload) };
    case initialCertificationArry.type:
      return { ...state, initialCertificationArry: action.payload };
    case recycledContentValue.type:
      return { ...state, recycledContentValue: action.payload };
    case clearFormValueTRF.type:
      return {
        ...state,
        trfDataForEdit: {},
        errorClassName: clearValues(),
        isMaterialFilled: clearMaterialFilled(),
        preLoadComponents: false,
        isRetestEnable:'No'
      };
    case onChangeRetest.type:
      return { ...state, isRetestEnable: action.payload };

    case '@@redux-form/INITIALIZE':
      return { ...state, errorClassName: validate(action,state.isRetestEnable) };
    case '@@redux-form/CHANGE':
      return { ...state, errorClassName: isValidated(action,state.isRetestEnable), isMaterialFilled: isMaterialFilled(action), updateEnable: defaultState.updateEnable };
    case '@@redux-form/RESET':
      return { ...state, errorClassName: clearValues(action), isMaterialFilled: clearMaterialFilled(action) };
    case createServiceAction('trfData', 'success').type:
      return { ...state, result: action.data,isRetestEnable:'No' };
    case createServiceAction('trfDetails', 'success').type:
      return { ...state, trfData: action.data };
    case createServiceAction('addMaterial', 'success').type:
      return { ...state, materialData: action.data };
    case createServiceAction('trfUpdate', 'requested').type:
      return { ...state, isLoading: true };
    case createServiceAction('locationSearch', 'error').type:
      return { ...state, isLoading: false };
    case createServiceAction('trfUpdate', 'success').type:
      return { ...state, trfDataForEdit: action.data, isLoading: false, preLoadComponents: true, updateEnable: false };
    case createServiceAction('trfUpdate', 'after_success').type:
      return { ...state, isLoading: false, preLoadComponents: false, updateEnable: false };
    default:
      return state;
  }
}