import { fromJS } from 'immutable';
import get from 'lodash/get';

import { createServiceAction } from '../util/actionHelper.js';


const defaultState = fromJS({
    getSuppliersNextPage: 1,
    suppliersLoading: false,
    supplierAllLoadFinish: false,
    getDetailsNextPage: 1,
});

export default function dashboard(state = defaultState, action) {
    state = fromJS(state);
    const pageCount = 100;
    switch (action.type) {

        case createServiceAction('listSuppliers', 'loading').type:
            return state.set('suppliersLoading', true)
                    .set('getSupplierProgress', 0);


        case createServiceAction('listSuppliers', 'server-error').type:
                return state.set('suppliersLoading', false)             
        case createServiceAction('listSuppliers', 'success').type:
            let nextPage = 1
            let totalPage_success = 0
            if (get(action.data, 'count') > pageCount) {
                nextPage = state.get('getSuppliersNextPage') + 1
                totalPage_success = Math.ceil(parseInt(get(action.data, 'count'), 10) / pageCount)
            }
            let tempForAll = action.data
            let updateTempForAll = fromJS(tempForAll)
            let loadFinish = get(action.data, 'totalPages')<pageCount ?true:false
            if (totalPage_success > 1 && nextPage === 2) {
                state.getSupplierProgress = (nextPage / totalPage_success) * 100
            }

            return state.set('suppliersData', updateTempForAll.toJS())
                .set('suppliersTotalCount', get(action.data, 'count'))
                .set('getSuppliersTotalPage', totalPage_success)
                .set('getSuppliersNextPage', nextPage)
                .set('getSuppliersRemainingPages', totalPage_success)
                .set('suppliersLoading', false)
                .set('suppliersTotalPages', get(action.data, 'totalPages'))
                .set('supplierAllLoadFinish', loadFinish)
                .set('getSupplierProgress', state.getSupplierProgress)
                


        case createServiceAction('listSuppliersLazyLoad', 'success').type:


            let newData = action.data.data

           

            let temp = state.get('suppliersData')
          
            let updateTemp = fromJS(temp)
           

            let InitialData = temp.data;
            

            InitialData = [...InitialData, ...newData];

            updateTemp = updateTemp.updateIn(['data'], index => InitialData)
            let nextSummaryPage = state.get('getSuppliersNextPage') + 1;
            let totalPage = Math.ceil(parseInt(get(action.data, 'count'), 10) / pageCount)
            if (nextSummaryPage > state.get('getSuppliersTotalPage')) {
                nextSummaryPage = 1
                totalPage = 0
            }
            if (nextSummaryPage <= totalPage && totalPage !== 0)
                state.getSupplierProgress = (nextSummaryPage / totalPage) * 100;
            else
                state.getSupplierProgress = 0;
            return state.setIn(['suppliersData'], updateTemp.toJS())
                .set('getSuppliersTotalPage', totalPage)
                .set('getSuppliersRemainingPages', Math.ceil(parseInt(get(action.data, 'count'), 10) / pageCount) - (state.get('getSuppliersNextPage')), 10)
                .set('getSuppliersNextPage', nextSummaryPage)
                .set('getSupplierProgress', getSupplierProgress)


        case createServiceAction('listSuppliersLazyLoad', 'completed').type:
            return state.set('supplierAllLoadFinish', true)

        default:
            return state;
    }
}



export const getsuppliersData = state => state.listAllSuppliers.get('suppliersData');
export const getSuppliersNextPage = state => state.listAllSuppliers.get('getSuppliersNextPage');
export const getSuppliersRemainingPages = state => state.listAllSuppliers.get('getSuppliersRemainingPages');
export const isSuppliersLoading = state => state.listAllSuppliers.get('suppliersLoading');
export const getSuppliersLoadStatus = state => state.listAllSuppliers.get('searchAllLoadFinish');
export const getTotalPages = state =>state.listAllSuppliers.get('suppliersTotalPages');
export const supplierAllLoadFinish = state => state.listAllSuppliers.get('supplierAllLoadFinish')
export const getSupplierProgress = state => state.listAllSuppliers.get('getSupplierProgress')


