import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';

import auth from './authReducer.js';
import user from './userReducer.js';
import navigation from './navigationReducer.js';
import offline from './offlineReducer.js';
import request from './requestReducer.js';
import notifications from './notificationsReducer';
import dashboard from './dashboardReducer';
import trf from './trfReducer';
import qrf from './qrfReducer';
import frf from './frfReducer';
import lab from './labReducer';
import testDetail from './testDetailReducer';
import downloadAttachment from './downloadAttachmentReducer';
import addLocation from './addLocationReducer'
import adminUser from './adminUserReducer.js';
import listNotification from './listNotificationReducer.js';
import licensee from './licenseeReducer.js';
import training from './trainingReducer.js';
import archive from './archiveReducer';
import materialList from './materialListReducer';
import listAllSuppliers from './listAllSupplierReducer';
import previewAttachment from './previewAttachmentReducer.js';
import { clearFormValueFRF } from '../actions/frfActions.js';
import { clearFormValueQRF } from '../actions/qrfActions.js';
import { clearFormValueTRF } from '../actions/trfActions.js';
import { clearFormValueLAB } from '../actions/labActions.js';


const form = formReducer.plugin({
  Frfinfo: (state, action) => {
    switch (action.type) {
      case clearFormValueFRF.type:
        return { ...state, values: null };
      default:
        return state;
    }
  },
  QrfInfo: (state, action) => {
    switch (action.type) {
      case clearFormValueQRF.type:
        return { ...state, values: null };
      default:
        return state;
    }
  },
  brandInfo: (state, action) => {
    switch (action.type) {
      case clearFormValueTRF.type:
        return { ...state, values: null };
      default:
        return state;
    }
  },
  testingInfo: (state, action) => {
    switch (action.type) {
      case clearFormValueTRF.type:
        return { ...state, values: null };
      default:
        return state;
    }
  },
  labLocation: (state, action) => {
    switch (action.type) {
      case clearFormValueTRF.type:
        return { ...state, values: null };
      default:
        return state;
    }
  },
  supplierForm: (state, action) => {
    switch (action.type) {
      case clearFormValueTRF.type:
        return { ...state, values: null };
      default:
        return state;
    }
  },
  LabReview: (state, action) => {
    switch (action.type) {
      case clearFormValueLAB.type:
        return { ...state, values: null };
      default:
        return state;
    }
  }
});

const rootReducer = combineReducers({
  routing,
  form,
  request,
  auth,
  user,
  navigation,
  offline,
  notifications,
  dashboard,
  trf,
  qrf,
  frf,
  lab,
  testDetail,
  addLocation,
  downloadAttachment,
  adminUser,
  listNotification,
  licensee,
  training,
  archive,
  materialList,
  listAllSuppliers,
  previewAttachment
});

export default rootReducer;
