import React, { Component } from 'react';
import { reduxForm, Field, getFormValues, reset } from 'redux-form';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import { createServiceAction } from '../../../util/actionHelper.js';
import { H4, H5, TextInput, Button, IconButton, DropDown, Spinner, ConfirmationBox } from '../../../components/index.js';
import { toggleAddSupplerButton } from '../../../actions/navigationActions.js';
import SupplierSearch from '../../supplierSearch/SupplierSearch.js';
import labLocations from '../../../constants/json/radiobuttons/location.json';
import {
  getSupplierList,
} from '../../../reducers/authReducer.js';
if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#app-host');

function getLabNames() {
  return labLocations.map(lab => ({
    id: lab.id,
    value: lab.id,
    label: lab.lab
  }));
}

function getLabLocations(labLocationNameIdentifier) {
  if (labLocationNameIdentifier) {
    const selectedLocation = labLocations.find(
      labLocation => labLocation.id === parseInt(labLocationNameIdentifier, 10)
    );
    return (
      selectedLocation &&
      selectedLocation.location.map(labLocation => ({
        id: labLocation.id,
        value: labLocation.id,
        label: `${labLocation.area},${labLocation.country}`
      }))
    );
  }
  return [];
}

function emailValidate(value) {
  let isValidEmail = false;
  if (value) {
    isValidEmail = value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? false
      : true
  }
  return isValidEmail;
}

class EditUserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidEmail: false,
      isUpdated:false,
      oshUpdate:false,
      oshIdArr:[],
      litmosUpdate:false,
      submitModal: false,
      selectedFileName:"",
      modalIsOpen: false,
      selectedFile: null
    };
    this.submitAddUserData = this.submitAddUserData.bind(this);
    this.updateAddUserData = this.updateAddUserData.bind(this)

  }

  componentDidMount() {
    const { toggleAddSupplerButton } = this.props;

    toggleAddSupplerButton();
  }
  componentDidUpdate(preProps){
    const { editUserProfileStates } = this.props;
    
    if(editUserProfileStates && preProps.editUserProfileStates && editUserProfileStates!== preProps.editUserProfileStates &&
       (preProps.editUserProfileStates.userId === editUserProfileStates.userId ||
       (preProps.editUserProfileStates.email === editUserProfileStates.email && this.emailValidate(editUserProfileStates.email))) ){
      this.setState({isUpdated:true})
    }
    if(editUserProfileStates && (editUserProfileStates.userType === 'Suppliers' || editUserProfileStates.userType === 'Factories') && preProps.editUserProfileStates.OSIds !== editUserProfileStates.OSIds && editUserProfileStates.OSIds[0] !== null){
        this.setState({oshIdArr:editUserProfileStates.OSIds,filteredSupp:editUserProfileStates.OSIds})

      }
  }

  submitAddUserData = () => {
    const { submitUserProfile, editUserProfileStates } = this.props;
    const reqObj = {};
    reqObj.name = editUserProfileStates.userName || '';
    reqObj.email = editUserProfileStates.email || '';
    if (editUserProfileStates.userType === 'Suppliers' || editUserProfileStates.userType === 'Factories') {
      reqObj.supplierName = editUserProfileStates.supplierName ;
      reqObj.supplierOrLocationId = editUserProfileStates.supplierOrLocationId;
    } else {
      reqObj.labId = editUserProfileStates.labId ? editUserProfileStates.labId : '';
      reqObj.supplierOrLocationId = editUserProfileStates.labLocationId;
    }
    reqObj.compBAddr = "";
    reqObj.contact = editUserProfileStates.contact;
    reqObj.userType = editUserProfileStates.userType;
    this.setState({isUpdated:false})
    submitUserProfile(reqObj);
  }

  updateAddUserData = (e) => {
    const { updateUserProfile, editUserProfileStates } = this.props;
    const { oshUpdate, litmosUpdate }= this.state;
    const resultArray = {};
    resultArray.name = editUserProfileStates.userName ;
    resultArray.email = editUserProfileStates.email ;
    resultArray.userID = editUserProfileStates.userId;
    resultArray.userType = editUserProfileStates.userType;
    resultArray.supplierName = (editUserProfileStates.userType === 'Suppliers') || (editUserProfileStates.userType === 'Factories') ? editUserProfileStates.supplierName : '';
    resultArray.supplierOrLocationId = (editUserProfileStates.userType === 'Suppliers') || (editUserProfileStates.userType === 'Factories') ?

      editUserProfileStates.supplierOrLocationId?editUserProfileStates.supplierOrLocationId:editUserProfileStates.supplierId : editUserProfileStates.labLocationId;
    resultArray.contact = editUserProfileStates.contact;
    resultArray.labId = (editUserProfileStates.userType === 'Labs') ? editUserProfileStates.labId : null;
    if(editUserProfileStates.OSHStatus !== "completed"){
      resultArray.osh = oshUpdate;
    }
    resultArray.OSHId = editUserProfileStates.OSHId;
    if(editUserProfileStates.userType === 'Factories' ||editUserProfileStates.userType === 'Suppliers'){
    let locIdarr = [];
    this.state.oshIdArr.filter(entry => locIdarr.push(entry.locId));
    resultArray.locIds=locIdarr;
    }
   //resultArray.osh = editUserProfileStates && editUserProfileStates.onboardingStatus === "completed" ? true: oshUpdate
    if(editUserProfileStates.litmosStatus !== "completed"){
      resultArray.litmos = litmosUpdate;
    }
    this.setState({isUpdated:false,oshUpdate:false,litmosUpdate:false})

    updateUserProfile(resultArray);
 }

  emailValidate = (value) => {
    let isValidEmail = false;
    if (value) {
      isValidEmail = value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? false
        : true
    }
    return isValidEmail;
  }

  shouldEnableSubmit = () => {
    const { editUserProfileStates, supplierStates } = this.props;

    if (editUserProfileStates) {
      if ((editUserProfileStates.userType === 'Suppliers' || editUserProfileStates.userType === 'Factories')&& supplierStates ) {
        return !!(editUserProfileStates.userName && this.emailValidate(editUserProfileStates.email));
      }
      else if (editUserProfileStates.userType === 'Labs') {
        return !!(editUserProfileStates.userName && this.emailValidate(editUserProfileStates.email) &&
          editUserProfileStates.labId && editUserProfileStates.labLocationId && editUserProfileStates.contact);
      } else if (editUserProfileStates.userType === '') {
        return false;
      }
    }
    return false;
  };

  shouldEnableUpdate = () => {
    const { editUserProfileStates } = this.props;
    const { isUpdated,litmosUpdate,oshUpdate } = this.state;
    if (editUserProfileStates) {
      if((editUserProfileStates.userType === 'Suppliers' || editUserProfileStates.userType === 'Factories')&& isUpdated)
      {
        return !!(editUserProfileStates.userName && this.emailValidate(editUserProfileStates.email));
      }
      else if (editUserProfileStates.userType === 'Labs' && isUpdated) {
        return !!(editUserProfileStates.labId && editUserProfileStates.labLocationId && editUserProfileStates.contact
          && editUserProfileStates.userName && this.emailValidate(editUserProfileStates.email));
      }
      else if (editUserProfileStates.userType === '') {
        return false;
      }
      else if(oshUpdate || litmosUpdate){
        return true
      }
    }
    return false;
  }
  onSupplierChange=(name,id) =>{
    const { change } = this.props;
     change('supplierName', name ? name : '');
    change('supplierOrLocationId', id? id : '');
    this.setState({isUpdated:true})
  }
  onOSIdChange=(OSHId)=>{
    var filtered=[];
    if(this.state.oshIdArr.length ===0){
      filtered= this.props.supplierList.filter(function(el) { return el.OSId !== OSHId; });
    }else{
      filtered= this.state.filteredSupp.filter(function(el) { return el.OSId !== OSHId;});
    }
   this.setState({oshIdArr:[...this.state.oshIdArr,OSHId],isUpdated:true,filteredSupp:filtered})
  }

  toggleModal =( status )=>{
    const { toggleModal } =this.props;
toggleModal(status)
    
    this.setState({oshUpdate:false, litmosUpdate:false})
    this.setState({isUpdated:false})
    this.setState({oshIdArr:[]})
  }
  handleOSH=(e)=>{
   this.setState({oshUpdate:e.target.checked})
  }
  handleLitmos=(e)=>{
    this.setState({litmosUpdate:e.target.checked})
   }
  handleRemoveClick = (index) => {
    this.state.oshIdArr.splice(index, 1)
    this.setState({oshIdArr:this.state.oshIdArr,isUpdated:true})
  };
  handleOSHIDChange =(e,i)=>{
    const list =this.state.oshIdArr;
    list[i] = e.target.value;
    this.setState({oshIdArr : list})
  }
  downloadFile = (requestParams) => {
    const { editUserProfileStates,previewAttachment } = this.props;
    previewAttachment({ ...requestParams, formType: 'trainingCertificate',userId: editUserProfileStates.userId, for: 'download' });
  }
  previewFile = (requestParams) => {
      const { previewAttachment, editUserProfileStates } = this.props;
      previewAttachment({ ...requestParams, formType: 'trainingCertificate',userId: editUserProfileStates.userId, for: 'preview' });
      this.openModal()
  }
  openModal = () => {
    const { previewAttachmentData } = this.props;
    if (previewAttachmentData && Object.keys(previewAttachmentData).length > 0) {
      let content = previewAttachmentData.data.Content;
      content = content.replace(/data/g, 'data:');
      content = content.replace(/pdf/g, 'pdf;');
      content = content.replace(/base64/g, 'base64,');
        this.setState({ selectedFile: content,modalIsOpen: true });
    }
  }
  closeModal = () => {
    this.setState({ modalIsOpen: false });
}
  openConfirmationPopup = (item)=> {
    this.setState({submitModal: true,selectedFileName:item});
  }
  closeSubmitModal = () => {
    this.setState({submitModal: false});
  };

  render() {
    const { isModalOpen,
      toggleModal,
      editUserProfileStates,
      clearUserDetailsFilled,
      isAdd,
    } = this.props;
    const { oshIdArr,submitModal } = this.state;
    const displaySupplier = ((editUserProfileStates && editUserProfileStates.userType === 'Suppliers'));
    const displayFactory = ((editUserProfileStates && editUserProfileStates.userType === 'Factories'));
    const displayLab = ((editUserProfileStates && editUserProfileStates.userType === 'Labs'));
    const oshStatus = editUserProfileStates && editUserProfileStates.OSHStatus === "completed" ? true : false;
    const displaySupplierSearch = editUserProfileStates && editUserProfileStates.supplierName === null ? false : editUserProfileStates.supplierName === undefined ? false : true;
    const litmosStatus = editUserProfileStates && editUserProfileStates.litmosStatus === "completed" ? true : false;
    return (
      <span>
        {
          (!isAdd && !editUserProfileStates) ?
            <Spinner large />
            :
            <Modal
            isOpen={isModalOpen}
            onRequestClose={() => { this.toggleModal(false); clearUserDetailsFilled() }}
            className="modal-container edit-user-modal-container"
            overlayClassName="modal-overlay"
            >
              <IconButton icon="times" className="modal-close-btn" onClick={() => { this.toggleModal(false); clearUserDetailsFilled() }} />
              <form className="form">
                <div className="p2-sm">
                  <H4 className="text-color-accent u-bold page-title-has-back sub-heading">{isAdd ? 'ADD USER DETAILS' : 'EDIT USER DETAILS'}</H4>
                </div>
                <div>
                  <div className="ncss-col-sm-12">
                    {editUserProfileStates && editUserProfileStates.email && !this.emailValidate(editUserProfileStates.email) ?
                      <div className="ncss-col-sm-12 ncss-input-container ">
                        <H5 className="ncss-col-sm-12 text-color-accent u-bold description p2-top-sm p2-bottom-sm">{`Please enter valid email address`}</H5>
                      </div> : null
                    }
                    <div className="ncss-col-sm-12 ncss-input-container ">
                      <H5 className="ncss-col-sm-3 u-bold description p2-top-sm p2-bottom-sm">Name</H5>

                      <div className="ncss-col-sm-9"> <Field component={TextInput} name="userName" type="text" /></div>
                    </div>
                    <div className="ncss-col-sm-12 ncss-input-container ">
                      <H5 className="ncss-col-sm-3 u-bold description p2-top-sm p2-bottom-sm">E-Mail</H5>
                      <div className="ncss-col-sm-9"> <Field component={TextInput} name="email" type="text"
                        validate={emailValidate}
                      /></div>
                    </div>
                    <div className="ncss-col-sm-12 ncss-input-container">
                      <H5 className="ncss-col-sm-3 u-bold description p2-top-sm p2-bottom-sm">User Type</H5>
                      <div className="ncss-col-sm-9">
                        <span className="dropdown-add-supplier">
                          <Field
                            component={DropDown}
                            placeholder="Select a user"
                            options={[{ id: 'lab', value: 'Labs', label: 'Lab' },
                            { id: 'supplier', value: 'Suppliers', label: 'Supplier' },
                            { id: 'factory', value: 'Factories', label: 'Factory' }]}
                            name="userType"
                          />
                        </span>
                      </div>
                    </div>
                    {
                      displaySupplier || displayFactory ?
                      <>
                        <div className="ncss-col-sm-12 ncss-input-container ">
                          <H5 className=" ncss-col-sm-3 u-bold description p2-top-sm p2-bottom-sm"> PCX Supplier Search</H5>
                          <div className="ncss-col-sm-9">
                            <SupplierSearch
                              includeInternal={false}
                              includePCX={true}
                              requsetLocation={false}
                              supplierChange = { this.onSupplierChange}
                              isEditUser={true}
                            />
                          </div>
                          {displaySupplierSearch &&<H5 className=" ncss-col-sm-3 u-bold description p2-top-sm p2-bottom-sm">PCX Supplier</H5>}
                          {displaySupplierSearch &&<div className="ncss-col-sm-9"> <Field component={TextInput} name="supplierName" type="text" disabled /></div>}
                          {/* <Field component={TextInput} name="supplierOrLocationId" type="hidden" /> */}

                        </div>
                       
                        </> :
                        <span>
                          {
                            displayLab ?
                              <div className="ncss-col-sm-12 ncss-input-container ">
                                <H5 className="ncss-col-sm-3 u-bold description p2-top-sm p2-bottom-sm">Lab Name</H5>
                                <div className="ncss-col-sm-9">
                                  <span className="dropdown-add-supplier">
                                    <Field
                                      component={DropDown}
                                      name="labId"
                                      placeholder="Select a Lab Name"
                                      options={getLabNames()}
                                      className="dropdown-border ie-border-padding-0-fix"
                                    />
                                  </span>
                                </div>
                                <div>
                                  <H5 className="ncss-col-sm-3 u-bold description p2-top-sm p2-bottom-sm">Lab Location</H5>
                                  <div className="ncss-col-sm-9">
                                    <span className="dropdown-add-supplier">
                                      <Field
                                        component={DropDown}
                                        name="labLocationId"
                                        placeholder="Select a Lab Location"
                                        options={getLabLocations(editUserProfileStates && editUserProfileStates.labId)}
                                        className="dropdown-border ie-border-padding-0-fix"
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div> : null}
                        </span>
                    }

                    <div className="ncss-col-sm-12 ncss-input-container ">
                      <H5 className=" ncss-col-sm-3 u-bold description p2-top-sm p2-bottom-sm">Phone</H5>
                      <div className="ncss-col-sm-9"><Field component={TextInput} name="contact" type="text" /></div>
                    </div>
                    {(displayFactory || displaySupplier) && !isAdd &&
                       <div className="ncss-col-sm-12 ncss-input-container p2-top-sm p2-bottom-sm">
                       <H5 className=" ncss-col-sm-3 u-bold description p2-top-sm p2-bottom-sm"> OS ID Search</H5>
                       <div className="ncss-col-sm-9">
                         <SupplierSearch
                           isOSIdSearch={true}
                           includeInternal={true}
                           requsetLocation={false}
                           osIdChange = { this.onOSIdChange}
                           filteredSupp={oshIdArr}
                         />
                       </div>
                       </div>
                       }
                    {(displayFactory || displaySupplier) && !isAdd &&<div className="ncss-col-sm-12 ncss-input-container ">
                      
                      {oshIdArr.map((item,i)=>{
                        return(
                          oshIdArr[i].OSId !== null && <div>
                          {i === 0 ? <H5 className=" ncss-col-sm-3 u-bold description p2-top-sm p2-bottom-sm">Other OS ID</H5>:
                          <div className=" ncss-col-sm-3"></div>} 
                          <div className="ncss-col-sm-5">
                          <input
                          className="ncss-input p2-top-sm pt2-sm pr4-sm pb2-sm pl4-sm"
                          placeholder="Enter OSID"
                          onChange={(e) => this.handleOSHIDChange(e, i)}
                            value={oshIdArr[i].OSId}
                            id={i}
                            type="text"
                            disabled
                          /></div>
                          <div className="ncss-col-sm-3 ncss-input-container">
                          <Button className=" ncss-col-sm-3 u-bold pb1-sm addmore-error" style={{backgroundColor:"white"}} onClick={() => this.handleRemoveClick(i)}>
                            Remove
                          </Button>
                     </div> 
                          </div>
                        )
                      
                      })}
                    </div>
                    }
                    {
                      (displayFactory || displaySupplier) && !isAdd ?
                        <div className="ncss-col-sm-12 ncss-input-container ">
                          <div className="p2-sm">
                            <H4 className="text-color-accent u-bold">CHECKLISTS</H4>
                          </div>
                          <div>
                          <H5 className="ncss-col-sm-3 u-bold description p2-top-sm p2-bottom-sm">OSH Completed</H5>
                          <input type="checkbox" name ="oshCompleted"  
                          disabled={oshStatus}
                          defaultChecked={oshStatus}
                          onChange ={ e =>this.handleOSH(e)}
                          />
                          {(!isAdd && editUserProfileStates.OSHId !== null) &&
                            <><H5 className=" ncss-col-sm-2 u-bold description p2-top-sm p2-bottom-sm textAlign">OS ID</H5>
                              <H5 className='ncss-col-sm-3'>{editUserProfileStates.OSHId}</H5></>
                          }
                          </div>
                          <div>
                            <H5 className="ncss-col-sm-3 u-bold description p2-top-sm p2-bottom-sm">Training Completed</H5>
                            <input type="checkbox" name="litmos"
                                disabled={litmosStatus}
                                defaultChecked={litmosStatus}
                                onChange={e => this.handleLitmos(e)}
                            />
                          {editUserProfileStates.litmos && editUserProfileStates.litmos.fileNames.length > 0  && editUserProfileStates.litmos.fileNames[0] &&<H5 className="ncss-col-sm-3 u-bold description p2-top-sm p2-bottom-sm">Training Certificates</H5>}
                            {editUserProfileStates.litmos && editUserProfileStates.litmos.fileNames.map((item, i) => {
                                return (
                                    <div className="file-row">

                                        { <div className="ncss-col-sm-3"></div>}
                                        <H5 className="ncss-col-sm-5 u-bold description" style={{paddingLeft:"20px"}}>
                                            {item}
                                        </H5>
                                        <i className="fas fa-eye" alt="Preview"  onClick={() => this.previewFile({ id: editUserProfileStates.litmos.id, fileName: item })} />
                                        <div className="inline-flex-display download_button" title="Download file" onClick={()=>{this.openConfirmationPopup(item)} }>
                                            <i className="fa fa-download download-fontSize"></i>
                                        </div> 
                                    </div>
                                );
                            })}
                        </div>
                        </div>
                        : null
                    }
                  </div>
                  <div className="ncss-col-sm-12 ">
                    <div className="bottom-section p4-top-sm">
                      <Button
                        className="ncss-btn-secondary-dark u-bold secondary-button trf-button-selected"
                        onClick={() => { this.toggleModal(false); clearUserDetailsFilled() }}
                      >
                        CANCEL
                      </Button>
                      {editUserProfileStates &&
                        editUserProfileStates.companyName &&
                        editUserProfileStates.phone ?
                        <span>
                          {isAdd ?
                            <Button className="ncss-btn-primary-dark u-bold submit-button-enable new-material"
                              onClick={() => { this.submitAddUserData() }}
                            >
                              SUBMIT
                            </Button> :
                            <Button className="ncss-btn-primary-dark u-bold submit-button-enable new-material"
                              onClick={() => { this.updateAddUserData(); clearUserDetailsFilled(); toggleModal(false); }}
                            >
                              UPDATE 
                            </Button>}
                        </span> :
                        <span>
                          {isAdd ?
                            <Button
                              className={`ncss-btn-primary-dark u-bold submit-button-enable trf-button-selected ${!this.shouldEnableSubmit() ? 'disabled' : ''
                                }`}
                              {...this.shouldEnableSubmit() && { onClick: () => { this.submitAddUserData(); toggleModal(false); clearUserDetailsFilled() } }}
                            >
                              SUBMIT
                            </Button>
                            :
                            <Button
                              className={`ncss-btn-primary-dark u-bold submit-button-enable trf-button-selected  ${!this.shouldEnableUpdate() ? 'disabled' : ''}`}

                              onClick={() => { this.updateAddUserData(); clearUserDetailsFilled(); toggleModal(false); }}
                            >
                              UPDATE
                            </Button>
                          }
                        </span>
                      }
                    </div>
                  </div>
                </div>
                <ConfirmationBox
                  message={
                    `Do you want to download the file ${this.state.selectedFileName}?`
                  }
                  submitModal={submitModal}
                  submitValue={
                    () => {
                      this.downloadFile({ id: editUserProfileStates.litmos.id, fileName: this.state.selectedFileName });
                    }
                  }
                  closeModal={this.closeSubmitModal}
                />
              </form>
              {this.state.selectedFile !== null &&
              <Modal
                            isOpen={this.state.modalIsOpen}
                            onRequestClose={this.closeModal}
                            contentLabel="File Preview"
                            overlayClassName="modal-overlay"
                        >
                            <IconButton icon="times" className="modal-close-btn" onClick={this.closeModal} />

                            <object data={this.state.selectedFile ? this.state.selectedFile : ''} type="application/pdf" width="100%" height="100%">
                            </object>
                            <button onClick={this.closeModal}>Close</button>
                        </Modal>
         }
            </Modal>
        }
      </span>
    );
  }
}

EditUserProfile.propTypes = {
  isModalOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  clearUserDetailsFilled: PropTypes.func,
  isAdd: PropTypes.bool,
  editUserProfileStates: PropTypes.object
};
EditUserProfile.defaultProps = {
  isAdd: true
};

const mapStateToProps = state => ({
  editUserProfileStates: getFormValues('editUserProfile')(state),
  supplierStates: getFormValues('supplierForm')(state),
  supplierList: getSupplierList(state),
  previewAttachmentData: state.previewAttachment.previewAttachmentData,
});

const mapDispatchToProps = dispatch => ({
  updateUserData: (data) => {
    dispatch(createServiceAction('editUserProfile', 'updated')(data));
  },
  clearUserDetailsFilled: () => {
    const forms = ['editUserProfile'];
    forms.map(form => dispatch(reset(form)));
  },
  submitUserProfile: data => {
    dispatch(createServiceAction('addUserProfile', 'requested')({ formValues: data }));
  },
  updateUserProfile: data => {
    dispatch(createServiceAction('updateUserProfile', 'requested')(data));
  },
  toggleAddSupplerButton: () => {
    dispatch(toggleAddSupplerButton());
  },
  downloadAttachment: data => {
    dispatch(createServiceAction('downloadAttachment', 'requested')(data));
  },
  previewAttachment: data => {
    dispatch(createServiceAction('previewAttachment', 'requested')(data));
  }
});

export default reduxForm({ form: 'editUserProfile',initialValues: {
  OSIds:[],
  OSHId:''
}, enableReinitialize: true})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditUserProfile));
