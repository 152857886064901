import { createServiceAction } from '../util/actionHelper.js';

const defaultState = {
previewAttachmentData: {},
};

export default function previewAttachment(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('previewAttachment', 'success').type:
     return { ...state, previewAttachmentData: action.data };
    case "CLEARATTACHMENT_REQUESTED":
      return { ...state, previewAttachmentData:{} };
    default:
      return state;
  }
}
