import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { put, takeEvery, call } from 'redux-saga/effects';
import download from 'downloadjs';

function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    if (action.data) {
      const options = {
        id: action.data.id,
        fileName: action.data.fileName,
        formType: action.data.formType,
        userId: action.data.userId,
      };
      const infoPopDuration = 1000;
      const res = yield call(getRequest, apiConfig.downloadAttachment.endPointUrl, options);
      // Display HTTP status error message
      const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res, infoPopDuration);
      if(!statusErrorMessage){
        const response = yield res.json();
        // Display server error message
        const serverErrorMessage = yield call(getServerValidationMessage, response, infoPopDuration);
        if (!serverErrorMessage && response.errorCode === 0) {
         let content = response.data.Content;
         content = content.replace(/data/g, 'data:');
         content = content.replace(/pdf/g, 'pdf;');
         content = content.replace(/base64/g, 'base64,');
          action.data.for === 'download' && download(content,response.data.fileName, response.data.contentType)
          yield put(createServiceAction(serviceType, 'success')(response));
        }
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* previewAttachmentSagas() {
  const serviceType = 'previewAttachment';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default previewAttachmentSagas;
